import { ActivityUpsertMany } from '@celum/work/app/core/model/entities/activity/activity.actions';
import { ActivityType } from '@celum/work/app/core/model/entities/activity/activity.model';
import { CommentUpsertMany } from '@celum/work/app/core/model/entities/comment/comment.actions';
import { CommentType } from '@celum/work/app/core/model/entities/comment/comment.model';
import { ContentItemUpsertMany } from '@celum/work/app/core/model/entities/content-item/content-item.actions';
import { ContentItemType } from '@celum/work/app/core/model/entities/content-item/content-item.model';
import { ContributorsUpsertMany, ContributorType } from '@celum/work/app/core/model/entities/contributor';
import { CustomFieldUpsertMany } from '@celum/work/app/core/model/entities/custom-field/custom-field.actions';
import { CustomFieldType } from '@celum/work/app/core/model/entities/custom-field/custom-field.model';
import { CustomFormUpsertMany } from '@celum/work/app/core/model/entities/custom-form/custom-form.actions';
import { CustomFormType } from '@celum/work/app/core/model/entities/custom-form/custom-form.model';
import { FileUpsertMany } from '@celum/work/app/core/model/entities/file/file.actions';
import { FileType } from '@celum/work/app/core/model/entities/file/file.model';
import { FileVersionsUpsertMany } from '@celum/work/app/core/model/entities/file-version/file-version.actions';
import { FileVersionType } from '@celum/work/app/core/model/entities/file-version/file-version.model';
import { FolderUpsertMany } from '@celum/work/app/core/model/entities/folder/folder.actions';
import { FolderType } from '@celum/work/app/core/model/entities/folder/folder.model';
import { ItemLinkUpsertMany } from '@celum/work/app/core/model/entities/item-link/item-link.actions';
import { ItemLinkType } from '@celum/work/app/core/model/entities/item-link/item-link.model';
import { MembersUpsertMany, MemberType } from '@celum/work/app/core/model/entities/member';
import { PersonsUpsertMany, PersonType } from '@celum/work/app/core/model/entities/person';
import { RenditionUpsertMany } from '@celum/work/app/core/model/entities/rendition/rendition.actions';
import { RenditionType } from '@celum/work/app/core/model/entities/rendition/rendition.model';
import { TaskListType, TasksUpsertMany, TaskType } from '@celum/work/app/core/model/entities/task';
import { TaskListsUpsertMany } from '@celum/work/app/core/model/entities/task/task-list.actions';
import { TeamspacesUpsertMany, TeamspaceType } from '@celum/work/app/core/model/entities/teamspace';
import { TemplateCategoryUpsertMany } from '@celum/work/app/core/model/entities/template-category/template-category.actions';
import { WorkroomsUpsertMany, WorkroomType } from '@celum/work/app/core/model/entities/workroom';

import { MarkerUpsertMany } from '../model/entities/marker/marker.actions';
import { MarkerType } from '../model/entities/marker/marker.model';
import { TemplateUpsertMany } from '../model/entities/template/template.actions';
import { TemplateType } from '../model/entities/template/template.model';
import { TemplateCategoryType } from '../model/entities/template-category/template-category.model';
import { WorkroomGroupUpsertMany } from '../model/entities/workroom/group/workroom-group.actions';
import { WorkroomGroupType } from '../model/entities/workroom/group/workroom-group.model';
import { WorkroomGroupItemUpsertMany } from '../model/entities/workroom/group-item/workroom-group-item.actions';
import { WorkroomGroupItemType } from '../model/entities/workroom/group-item/workroom-group-item.model';

export const ACTION_BY_TYPE = {
  [PersonType.TYPE_KEY]: (entities: any) => PersonsUpsertMany({ persons: entities }),
  [WorkroomType.TYPE_KEY]: (entities: any, propertiesToUpdate: string[]) =>
    WorkroomsUpsertMany({
      workrooms: entities,
      propertiesToUpdate: propertiesToUpdate
    }),
  [MemberType.TYPE_KEY]: (entities: any) => MembersUpsertMany({ members: entities }),
  [TeamspaceType.TYPE_KEY]: (entities: any) => TeamspacesUpsertMany({ teamspaces: entities }),
  [ContributorType.TYPE_KEY]: (entities: any) => ContributorsUpsertMany({ contributors: entities }),
  [TaskListType.TYPE_KEY]: (entities: any) => TaskListsUpsertMany({ taskLists: entities }),
  [TaskType.TYPE_KEY]: (entities: any, propertiesToUpdate: string[]) =>
    TasksUpsertMany({
      tasks: entities,
      propertiesToUpdate: propertiesToUpdate
    }),
  [ItemLinkType.TYPE_KEY]: (entities: any) => ItemLinkUpsertMany({ itemLinks: entities }),
  [FileType.TYPE_KEY]: (entities: any, propertiesToUpdate: string[]) =>
    FileUpsertMany({
      files: entities,
      propertiesToUpdate: propertiesToUpdate
    }),
  [CommentType.TYPE_KEY]: (entities: any) => CommentUpsertMany({ comments: entities }),
  [MarkerType.TYPE_KEY]: (entities: any) => MarkerUpsertMany({ markers: entities }),
  [ActivityType.TYPE_KEY]: (entities: any) => ActivityUpsertMany({ activities: entities }),
  [TemplateCategoryType.TYPE_KEY]: (entities: any) => TemplateCategoryUpsertMany({ templateCategories: entities }),
  [TemplateType.TYPE_KEY]: (entities: any) => TemplateUpsertMany({ templates: entities }),
  [ContentItemType.TYPE_KEY]: (entities: any, propertiesToUpdate: string[]) =>
    ContentItemUpsertMany({
      contentItems: entities,
      propertiesToUpdate: propertiesToUpdate
    }),
  [FolderType.TYPE_KEY]: (entities: any, propertiesToUpdate: string[]) =>
    FolderUpsertMany({
      folders: entities,
      propertiesToUpdate: propertiesToUpdate
    }),
  [FileVersionType.TYPE_KEY]: (entities: any, propertiesToUpdate: string[]) =>
    FileVersionsUpsertMany({ fileVersions: entities, propertiesToUpdate }),
  [RenditionType.TYPE_KEY]: (entities: any, propertiesToUpdate: string[]) =>
    RenditionUpsertMany({ renditions: entities, propertiesToUpdate }),
  [WorkroomGroupType.TYPE_KEY]: (entities: any) => WorkroomGroupUpsertMany({ workroomGroups: entities }),
  [WorkroomGroupItemType.TYPE_KEY]: (entities: any) => WorkroomGroupItemUpsertMany({ workroomGroupItems: entities }),
  [CustomFieldType.TYPE_KEY]: (entities: any, propertiesToUpdate: string[]) =>
    CustomFieldUpsertMany({ customFields: entities, propertiesToUpdate }),
  [CustomFormType.TYPE_KEY]: (entities: any, propertiesToUpdate: string[]) =>
    CustomFormUpsertMany({ customForms: entities, propertiesToUpdate })
};
