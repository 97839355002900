import { EntityState } from '@ngrx/entity';
import { Store } from '@ngrx/store';
import { schema } from 'normalizr';
import { Observable } from 'rxjs';

import { DataUtil, EntityType } from '@celum/core';
import { Entity } from '@celum/work/app/core/model';

import { FileType } from '../file/file.model';
import { PersonType } from '../person';
import { Task, TaskType } from '../task/task.model';

export enum TargetContext {
  TASK = 'TASK'
}

export enum ItemLinkRelationType {
  NECESSARY_FOR = 'NECESSARY_FOR',
  DEPENDS_ON = 'DEPENDS_ON'
}

export function getOppositeTaskLinkRelationType(type: ItemLinkRelationType): ItemLinkRelationType {
  return type === ItemLinkRelationType.DEPENDS_ON
    ? ItemLinkRelationType.NECESSARY_FOR
    : ItemLinkRelationType.DEPENDS_ON;
}

export interface ItemLink extends Entity<string, ItemLinkType> {
  type: ItemLinkRelationType;
  targetId: number;
  sourceId: number;
  targetContext: TargetContext;

  targetObject: (store: Store<any>) => Observable<Task>;
}

export class ItemLinkType implements EntityType {
  public static TYPE_KEY = 'ItemLink';

  private static _instance: ItemLinkType;

  public id = ItemLinkType.TYPE_KEY;
  public inheritsFrom = new Set<EntityType>();

  public static instance(): ItemLinkType {
    return this._instance || (this._instance = new this());
  }

  public getSchema(relations?: {
    definitions?: { [key: string]: schema.Entity | [schema.Entity] };
    relationsFor?: string[];
  }): schema.Entity {
    const relationDefinitions: { [key: string]: any } = (relations || {}).definitions || {};
    const relationsFor = (relations || {}).relationsFor;

    if (!DataUtil.isEmpty(relationsFor)) {
      if (relations.relationsFor.includes(TaskType.TYPE_KEY)) {
        relationDefinitions.targetObject = TaskType.instance().getSchema({
          relationsFor: [PersonType.TYPE_KEY, FileType.TYPE_KEY]
        });
      }
    }

    return new schema.Entity(ItemLinkType.TYPE_KEY, relationDefinitions, {
      idAttribute: value => `${value.sourceId}_${value.type}_${value.targetId}`
    });
  }
}

export interface ItemLinkState extends EntityState<ItemLink> {}
