import { Store } from '@ngrx/store';

import { EntityTranslator } from '@celum/work/app/core/model';

import { ItemLink, ItemLinkType } from './item-link.model';
import { selectTaskById } from '../task/task.selectors';

export class ItemLinkTranslator implements EntityTranslator<ItemLink> {
  public translateToEntity(json: any): ItemLink {
    return {
      id: `${json.sourceId}_${json.type}_${json.targetId}`,
      targetId: json.targetId,
      type: json.type,
      entityType: ItemLinkType.instance(),
      sourceId: json.sourceId,
      targetContext: json.targetContext,
      targetObject: (store: Store<any>) => store.select(selectTaskById(json.targetId))
    };
  }
}
