import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { schema } from 'normalizr';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { SortDirection } from '@celum/common-components';
import { CelumPropertiesProvider, DataUtil, Entity, PaginationResult } from '@celum/core';
import { LocalStorageService } from '@celum/work/app/core';
import { CommunicationServerResponse } from '@celum/work/app/core/communication/response.model';
import { MetaInfo, Paging, Sorting } from '@celum/work/app/core/model';
import { File, FileTaskCount, FileType } from '@celum/work/app/core/model/entities/file/file.model';
import { ItemLinkRelationType } from '@celum/work/app/core/model/entities/item-link/item-link.model';
import { PersonType } from '@celum/work/app/core/model/entities/person';
import {
  Task,
  taskCockpitProperties,
  TaskCreationStrategy,
  taskDetailProperties,
  TaskPriority,
  TaskPropertiesToUpdate,
  TaskType,
  taskUpdateProperties
} from '@celum/work/app/core/model/entities/task';
import { TaskFormAutofillData } from '@celum/work/app/core/model/subsequent-action.model';
import { TaskInboxFilter } from '@celum/work/app/pages/dashboard/components/my-tasks/store/my-tasks.model';
import { CopyTaskDetailInclusionWithFormCopyStrategyValue } from '@celum/work/app/pages/workroom/pages/tasks/pages/task-detail/components/task-detail-dialog/copy-task-dialog/copy-task-detail-inclusion/copy-task-detail-inclusion.component';
import { TaskFilter } from '@celum/work/app/pages/workroom/pages/tasks/store/tasks-overview.model';
import { DueDateBucket } from '@celum/work/app/shared/components/filter-sort/filter/filter.model';
import { FilterUtils } from '@celum/work/app/shared/components/filter-sort/filter/filter.utils';
import { SortInfo } from '@celum/work/app/shared/components/filter-sort/sorter/sorter.component';
import { SearchQuery } from '@celum/work/app/shared/components/global-search';
import { WindowResizeService } from '@celum/work/app/shared/util';
import { STRONGLY_CONSISTENT_OPTION } from '@celum/work/app/shared/util/api-util';

import { ResultConsumerService } from '../../communication/result-consumer.service';

export interface TaskSearchResponse {
  [key: string]: {
    taskIds: number[];
    previewFilesIds?: string[];
    assignedTaskCount: number;
    paginationResult: PaginationResult;
  };
}

export interface TaskFilterDTO {
  name?: string;
  assignedToIds?: string[];
  createdByIds?: string[];
  priorities?: TaskPriority[];
}

export interface TaskInboxFilterDTO {
  name?: string;
  workroomIds?: string[];
  dueDateBuckets?: DueDateBucket[];
  priorities?: TaskPriority[];
}

@Injectable({ providedIn: 'root' })
export class TaskService {
  constructor(
    private httpClient: HttpClient,
    private resultConsumerService: ResultConsumerService,
    private localStorageService: LocalStorageService
  ) {}

  public loadTasksForListsConsideringVisibleColumns(
    workroomId: number,
    taskListIds: number[],
    paging: Paging,
    taskFilter: TaskFilter,
    currentUserId: number
  ): Observable<TaskSearchResponse> {
    const visibleColumns = taskListIds.map(id =>
      Number(this.localStorageService.getItem(`taskList_${id}_${currentUserId}_visibleColumns`) ?? 1)
    );
    const maxVisibleColumn = Math.max(...visibleColumns, 1);
    paging.limit = WindowResizeService.limitBatchSize(paging.limit * maxVisibleColumn);

    const options = {
      // define where to get the id for the task list from
      idAttribute: (value: any, parent: any, key: string) => key,
      // add the id to the resulting task list entry
      processStrategy: (value: any, parent: any, key: string) => ({
        ...value,
        id: key
      })
    };

    const taskListEntriesSchema = new schema.Entity(
      'taskListEntry',
      { results: [TaskType.instance().getSchema({ relationsFor: [PersonType.TYPE_KEY, FileType.TYPE_KEY] })] },
      options
    );

    const assignedTaskCountEntriesSchema = new schema.Entity(
      'assignedTaskCountEntry',
      new schema.Entity('count'),
      options
    );

    const resultSchema = {
      tasksByTaskListId: new schema.Values({ taskListEntry: taskListEntriesSchema }, () => 'taskListEntry'),
      assignedTaskCount: new schema.Values(
        { assignedTaskCountEntry: assignedTaskCountEntriesSchema },
        () => 'assignedTaskCountEntry'
      )
    };

    const metaInfo = MetaInfo.of([TaskType.TYPE_KEY, PersonType.TYPE_KEY, ...FileType.TYPE_KEY_NESTED], resultSchema);
    metaInfo.partialUpdates = { [TaskType.TYPE_KEY]: taskDetailProperties };

    const filter = FilterUtils.toTaskFilterDTO(taskFilter);
    const sorting = new Sorting('id', SortDirection.DESC);

    const body = {
      workroomId,
      filter,
      paging,
      sorting,
      taskListIds
    };

    return this.httpClient.post(`${CelumPropertiesProvider.properties.httpBaseAddress}/tasks/search`, body).pipe(
      map(res => {
        const entitiesResult = this.resultConsumerService.translateAndAddToStore(res, metaInfo);

        const listEntries = entitiesResult.objects.taskListEntry || [];

        const result: TaskSearchResponse = {};

        type TaskEntity = Entity & { previewFileId: string };
        const taskPreviewFileDic = entitiesResult?.entities?.Task.reduce<Record<string, string>>(
          (acc, curr: TaskEntity) => ({
            ...acc,
            [curr.id]: curr.previewFileId
          }),
          {}
        );
        listEntries.forEach(listEntry => {
          result[listEntry.id] = {
            taskIds: listEntry.results,
            previewFilesIds:
              listEntry.results.map(task => taskPreviewFileDic[task]).filter(previewFileId => !!previewFileId) || [],
            assignedTaskCount: (res as any).assignedTaskCount[listEntry.id],
            paginationResult: {
              hasTop: false,
              hasBottom: listEntry.paginationInformation.elementsFollow,
              totalElementCount: listEntry.paginationInformation.totalElementCount
            }
          };
        });
        return result;
      })
    );
  }

  public loadMyTasks(
    paging: Paging,
    taskInboxFilter: TaskInboxFilter,
    sort: SortInfo
  ): Observable<{ tasks: Task[]; paginationResult: PaginationResult }> {
    const filter = FilterUtils.toTaskInboxFilterDto(taskInboxFilter);

    const body = {
      ...filter,
      paging,
      sorting: Sorting.of(sort.value, sort.direction)
    };

    const resultsSchema = {
      results: [TaskType.instance().getSchema({ relationsFor: [PersonType.TYPE_KEY, FileType.TYPE_KEY] })]
    };
    const metaInfo = MetaInfo.of(
      [TaskType.TYPE_KEY, ...FileType.TYPE_KEY_NESTED],
      resultsSchema,
      [TaskType.TYPE_KEY],
      'results'
    );
    metaInfo.partialUpdates = { [TaskType.TYPE_KEY]: taskCockpitProperties };

    return this.httpClient
      .post(`${CelumPropertiesProvider.properties.httpBaseAddress}/tasks/task-inbox-search`, body)
      .pipe(
        map(response => {
          const entitiesResult = this.resultConsumerService.translateAndAddToStore(response, metaInfo);
          return {
            tasks: entitiesResult.entities[TaskType.TYPE_KEY] as Task[],
            paginationResult: entitiesResult.paginationResult
          };
        })
      );
  }

  public getTaskById(taskId: number): Observable<Task> {
    const metaInfo = MetaInfo.of(
      [TaskType.TYPE_KEY, PersonType.TYPE_KEY, ...FileType.TYPE_KEY_NESTED],
      TaskType.instance().getSchema({ relationsFor: [PersonType.TYPE_KEY, FileType.TYPE_KEY] })
    );
    metaInfo.partialUpdates[TaskType.TYPE_KEY] = [...taskDetailProperties];
    return this.httpClient
      .get(`${CelumPropertiesProvider.properties.httpBaseAddress}/tasks/${taskId}`)
      .pipe(map(res => this.handleSingleTaskResult(res, metaInfo)));
  }

  public createTask({ name, sort, taskListId }: Task): Observable<Task> {
    const body = {
      name,
      sort,
      taskListId,
      typeKey: TaskType.TYPE_KEY
    };

    const metaInfo = MetaInfo.of([TaskType.TYPE_KEY, ...FileType.TYPE_KEY_NESTED], TaskType.instance().getSchema());
    return this.httpClient
      .post(`${CelumPropertiesProvider.properties.httpBaseAddress}/tasks`, body)
      .pipe(map(res => this.handleSingleTaskResult(res, metaInfo)));
  }

  public createTaskFromPortal(taskListId: number, portalId: string, taskForm: TaskFormAutofillData): Observable<Task> {
    const body = {
      taskListId,
      portalId,
      taskForm
    };

    const metaInfo = MetaInfo.of([TaskType.TYPE_KEY, ...FileType.TYPE_KEY_NESTED], TaskType.instance().getSchema());
    metaInfo.partialUpdates = { [TaskType.TYPE_KEY]: taskUpdateProperties };

    return this.httpClient
      .post(`${CelumPropertiesProvider.properties.httpBaseAddress}/tasks/from-portal`, body)
      .pipe(map(res => this.handleSingleTaskResult(res, metaInfo)));
  }

  public convertToTask(taskId: number, subtaskId: number, taskListId: number): Observable<Task> {
    const body = {
      targetTaskListId: taskListId
    };

    const metaInfo = MetaInfo.of([TaskType.TYPE_KEY, ...FileType.TYPE_KEY_NESTED], TaskType.instance().getSchema());

    return this.httpClient
      .post<Task>(
        `${CelumPropertiesProvider.properties.httpBaseAddress}/tasks/${taskId}/subtasks/${subtaskId}/convert`,
        body,
        STRONGLY_CONSISTENT_OPTION
      )
      .pipe(map(res => this.handleSingleTaskResult(res, metaInfo)));
  }

  public createTasksFromFiles(
    taskListId: number,
    contentItemIds: string[],
    taskName: string,
    taskCreationStrategy: TaskCreationStrategy
  ): Observable<Task[]> {
    const body = {
      taskName,
      taskListId,
      contentItemIds,
      typeKey: taskCreationStrategy
    };

    const metaInfo = MetaInfo.of([TaskType.TYPE_KEY, ...FileType.TYPE_KEY_NESTED], TaskType.instance().getSchema());
    metaInfo.partialUpdates = { [TaskType.TYPE_KEY]: taskUpdateProperties };

    return this.httpClient
      .post(`${CelumPropertiesProvider.properties.httpBaseAddress}/tasks/from-files`, body, STRONGLY_CONSISTENT_OPTION)
      .pipe(map(res => this.handleMultipleTaskResult(res, metaInfo)));
  }

  public updateTask({ id }: Task, propertiesToUpdate: TaskPropertiesToUpdate): Observable<Task> {
    const metaInfo = MetaInfo.of([TaskType.TYPE_KEY, ...FileType.TYPE_KEY_NESTED], TaskType.instance().getSchema());
    metaInfo.partialUpdates = { [TaskType.TYPE_KEY]: taskUpdateProperties };

    return this.httpClient
      .patch(`${CelumPropertiesProvider.properties.httpBaseAddress}/tasks/${id}`, { ...propertiesToUpdate })
      .pipe(map(res => this.handleSingleTaskResult(res, metaInfo)));
  }

  public moveTasks(
    tasks: Task[],
    taskListId: number,
    sort: number,
    filteredTaskIds: number[] = []
  ): Observable<Task[]> {
    const metaInfo = MetaInfo.of([TaskType.TYPE_KEY, ...FileType.TYPE_KEY_NESTED], TaskType.instance().getSchema());
    // tasks only contain their basic information!
    metaInfo.partialUpdates = { [TaskType.TYPE_KEY]: taskUpdateProperties };

    const body = {
      targetTaskListId: taskListId,
      taskIds: tasks.map(task => task.id),
      sort
    };

    return this.httpClient.post<Task[]>(`${CelumPropertiesProvider.properties.httpBaseAddress}/tasks/move`, body).pipe(
      map(res => {
        if (filteredTaskIds.length) {
          return this.handleMultipleTaskResult(
            res.filter(task => filteredTaskIds.includes(task.id)),
            metaInfo
          );
        }

        return this.handleMultipleTaskResult(res, metaInfo);
      })
    );
  }

  public copyTask(
    sourceTaskId: number,
    name: string,
    targetTaskListId: number,
    copiableProperties: CopyTaskDetailInclusionWithFormCopyStrategyValue
  ): Observable<Task> {
    const body = {
      sourceTaskId,
      name,
      targetTaskListId,
      configuration: {
        properties: { ...copiableProperties.copiableProperties },
        formConfiguration: copiableProperties.formCopyStrategy
      }
    };

    const metaInfo = MetaInfo.of([TaskType.TYPE_KEY, ...FileType.TYPE_KEY_NESTED], TaskType.instance().getSchema());
    return this.httpClient
      .post(`${CelumPropertiesProvider.properties.httpBaseAddress}/tasks/copy`, body)
      .pipe(map(res => this.handleSingleTaskResult(res, metaInfo)));
  }

  public deleteTasks(taskIds: number[]): Observable<void> {
    const httpOptions = {
      headers: new HttpHeaders(),
      body: taskIds,
      STRONGLY_CONSISTENT_OPTION
    };

    return this.httpClient.delete(`${CelumPropertiesProvider.properties.httpBaseAddress}/tasks`, httpOptions).pipe(
      map(() => {
        return void 0;
      })
    );
  }

  public assignPersonToTask(taskId: number, personId): Observable<void> {
    return this.httpClient
      .post(`${CelumPropertiesProvider.properties.httpBaseAddress}/tasks/${taskId}/assign/${personId}`, null)
      .pipe(
        map(() => {
          return void 0;
        })
      );
  }

  public unassignPersonFromTask(taskId: number, personId): Observable<void> {
    return this.httpClient
      .delete(`${CelumPropertiesProvider.properties.httpBaseAddress}/tasks/${taskId}/assign/${personId}`)
      .pipe(
        map(() => {
          return void 0;
        })
      );
  }

  public moveWithBulkAssignment(
    tasks: Task[],
    taskListId: number,
    sort: number,
    personIds: number[]
  ): Observable<void> {
    const metaInfo = MetaInfo.of([TaskType.TYPE_KEY, ...FileType.TYPE_KEY_NESTED], TaskType.instance().getSchema());
    // tasks only contain their basic information!
    metaInfo.partialUpdates = { [TaskType.TYPE_KEY]: taskUpdateProperties };

    const body = {
      targetTaskListId: taskListId,
      taskIds: tasks.map(task => task.id),
      sort,
      personIds
    };

    return this.httpClient
      .post<void>(`${CelumPropertiesProvider.properties.httpBaseAddress}/tasks/move-with-assignment`, body)
      .pipe(map(res => this.handleMultipleTaskResult(res, metaInfo)));
  }

  public deleteTaskContentItems(taskId: number, contentItemIds: string[]): Observable<void> {
    const httpOptions = {
      headers: new HttpHeaders(),
      body: contentItemIds
    };

    return this.httpClient
      .delete(`${CelumPropertiesProvider.properties.httpBaseAddress}/task/${taskId}/attachment`, httpOptions)
      .pipe(
        map(() => {
          return void 0;
        })
      );
  }

  public addAttachmentsToTask(taskId: number, contentItemIds: string[]): Observable<File[]> {
    return this.httpClient
      .post(
        `${CelumPropertiesProvider.properties.httpBaseAddress}/task/${taskId}/attachment`,
        contentItemIds,
        STRONGLY_CONSISTENT_OPTION
      )
      .pipe(
        map(res => {
          const metaInfo = MetaInfo.of(FileType.TYPE_KEY_NESTED, [FileType.instance().getSchema()]);
          const entitiesResult = this.resultConsumerService.translateAndAddToStore(res, metaInfo);
          return entitiesResult.entities[FileType.TYPE_KEY] as File[];
        })
      );
  }

  public searchForTask({ queryString, workroomId, limit }: SearchQuery): Observable<TaskSearchResult> {
    const body = {
      name: queryString,
      workroomId: workroomId || null,
      paging: Paging.of(0, limit),
      sorting: Sorting.of('modifiedDate', SortDirection.DESC)
    };

    const resultsSchema = {
      results: [
        {
          task: TaskType.instance().getSchema({ relationsFor: [PersonType.TYPE_KEY, FileType.TYPE_KEY] }),
          preview: FileType.instance().getSchema()
        }
      ]
    };

    const metaInfo = MetaInfo.of([TaskType.TYPE_KEY, ...FileType.TYPE_KEY_NESTED], resultsSchema, [], 'results');

    return this.httpClient
      .post<
        CommunicationServerResponse<Task>
      >(`${CelumPropertiesProvider.properties.httpBaseAddress}/tasks/global-search`, body)
      .pipe(
        map(response => {
          const entitiesResult = this.resultConsumerService.translateAndAddToStore(response, metaInfo);
          return {
            results: entitiesResult.entities[TaskType.TYPE_KEY] as Task[],
            paginationInformation: entitiesResult.paginationResult
          };
        })
      );
  }

  public searchForLinkedTask(
    currentItemId: number,
    queryString: string,
    workroomIds: number[],
    type: ItemLinkRelationType,
    limit: number
  ): Observable<{ tasks: Task[]; paginationResult: PaginationResult }> {
    const body = {
      currentItemId,
      name: queryString,
      workroomIds,
      type,
      paging: Paging.of(0, limit),
      sorting: Sorting.of('createdOn', SortDirection.DESC)
    };

    const metaInfo = this.getMetaInfoForTasksSearch();
    metaInfo.partialUpdates = { [TaskType.TYPE_KEY]: taskUpdateProperties };

    return this.httpClient
      .post<
        CommunicationServerResponse<Task>
      >(`${CelumPropertiesProvider.properties.httpBaseAddress}/tasks/search-linking`, body)
      .pipe(
        map(response => {
          const entitiesResult = this.resultConsumerService.translateAndAddToStore(response, metaInfo);
          return {
            tasks: entitiesResult.entities[TaskType.TYPE_KEY] as Task[],
            paginationResult: entitiesResult.paginationResult
          };
        })
      );
  }

  public getFileTaskCounts(workroomId: number, fileIds: string[]): Observable<FileTaskCount[]> {
    return this.httpClient.post<FileTaskCount[]>(
      `${CelumPropertiesProvider.properties.httpBaseAddress}/tasks/file-task-counts`,
      {
        workroomId,
        fileIds
      }
    );
  }

  public getFileTasks(
    config: {
      fileId: string;
      offset: number;
      batchSize: number;
    },
    workroomId: number
  ): Observable<{ tasks: Task[]; paginationResult: PaginationResult }> {
    const { fileId, offset, batchSize } = config;

    const resultsSchema = {
      results: [TaskType.instance().getSchema({ relationsFor: [PersonType.TYPE_KEY, FileType.TYPE_KEY] })]
    };
    const metaInfo = MetaInfo.of(
      [TaskType.TYPE_KEY, ...FileType.TYPE_KEY_NESTED],
      resultsSchema,
      [TaskType.TYPE_KEY],
      'results'
    );

    return this.httpClient
      .post<CommunicationServerResponse<Task>>(
        `${CelumPropertiesProvider.properties.httpBaseAddress}/tasks/file-task-search`,
        {
          workroomId,
          attachmentId: fileId,
          sorting: Sorting.of('name', SortDirection.ASC),
          paging: Paging.of(offset, batchSize)
        }
      )
      .pipe(
        map(res => {
          const entitiesResult = this.resultConsumerService.translateAndAddToStore(res, metaInfo);
          return {
            tasks: entitiesResult.entities[TaskType.TYPE_KEY] as Task[],
            paginationResult: entitiesResult.paginationResult
          };
        })
      );
  }

  public advancedSearch(params: {
    queryString: string;
    offset: number;
    limit: number;
    sort: SortInfo;
    priorities: TaskPriority[];
    dueDateBuckets: DueDateBucket[];
    assignedToIds: number[];
    workroomIds: number[];
  }): Observable<{ tasks: Task[]; paginationResult: PaginationResult }> {
    const { queryString, offset, limit, sort, dueDateBuckets, priorities, assignedToIds, workroomIds } = params;

    const body = {
      name: queryString,
      assignedToIds,
      workroomIds,
      priorities,
      dueDateBuckets,
      paging: {
        offset,
        limit
      },
      sorting: {
        field: sort.value,
        direction: sort.direction
      }
    };

    const resultsSchema = {
      results: [TaskType.instance().getSchema({ relationsFor: [PersonType.TYPE_KEY, FileType.TYPE_KEY] })]
    };

    const metaInfo = MetaInfo.of(
      [TaskType.TYPE_KEY, ...FileType.TYPE_KEY_NESTED, PersonType.TYPE_KEY],
      resultsSchema,
      [TaskType.TYPE_KEY],
      'results'
    );

    return this.httpClient
      .post(`${CelumPropertiesProvider.properties.httpBaseAddress}/tasks/advanced-search`, body)
      .pipe(
        map(res => {
          const entitiesResult = this.resultConsumerService.translateAndAddToStore(res, metaInfo);
          return {
            tasks: entitiesResult.entities[TaskType.TYPE_KEY] as Task[],
            paginationResult: entitiesResult.paginationResult
          };
        })
      );
  }

  public handleSingleTaskResult(res, metaInfo) {
    const entitiesResult = this.resultConsumerService.translateAndAddToStore(res, metaInfo);
    const tasks = entitiesResult.entities[TaskType.TYPE_KEY];
    return DataUtil.isEmpty(tasks) ? null : (tasks[0] as Task);
  }

  private handleMultipleTaskResult(res, metaInfo) {
    if (DataUtil.isEmpty(res) || !res || !res.length) {
      return null;
    }
    return res.map(item => this.handleSingleTaskResult(item, metaInfo));
  }

  private getMetaInfoForTasksSearch(): MetaInfo {
    const resultsSchema = {
      results: [TaskType.instance().getSchema({ relationsFor: [PersonType.TYPE_KEY, FileType.TYPE_KEY] })]
    };
    const metaInfo = MetaInfo.of(
      [TaskType.TYPE_KEY, ...FileType.TYPE_KEY_NESTED],
      resultsSchema,
      [TaskType.TYPE_KEY],
      'results'
    );
    return metaInfo;
  }
}

export interface TaskSearchResult {
  results: Task[];
  paginationInformation: PaginationResult;
}
