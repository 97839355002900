import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, map, Observable } from 'rxjs';

import { CelumPropertiesProvider, DataUtil, PaginationResult } from '@celum/core';
import { CommunicationServerResponse } from '@celum/work/app/core/communication/response.model';
import { ResultConsumerService } from '@celum/work/app/core/communication/result-consumer.service';
import { MetaInfo, Paging, Sorting } from '@celum/work/app/core/model';
import { FileType } from '@celum/work/app/core/model/entities/file/file.model';
import {
  ItemLink,
  ItemLinkRelationType,
  ItemLinkType,
  TargetContext
} from '@celum/work/app/core/model/entities/item-link/item-link.model';
import { PersonType } from '@celum/work/app/core/model/entities/person';
import { Task, TaskType, taskUpdateProperties } from '@celum/work/app/core/model/entities/task';
import { STRONGLY_CONSISTENT_OPTION } from '@celum/work/app/shared/util/api-util';

export interface LoadTaskLinksByTypeArgs {
  sourceId: number;
  itemLinkRelationType: ItemLinkRelationType;
  sorting: Sorting;
  paging: Paging;
}

@Injectable({ providedIn: 'root' })
export class ItemLinkService {
  constructor(
    private httpClient: HttpClient,
    private resultConsumerService: ResultConsumerService
  ) {}

  public loadItemLinksByType({
    sourceId,
    itemLinkRelationType,
    sorting,
    paging
  }: LoadTaskLinksByTypeArgs): Observable<{ itemLinks: ItemLink[]; paginationResult: PaginationResult }> {
    const resultsSchema = {
      results: [ItemLinkType.instance().getSchema({ relationsFor: [TaskType.TYPE_KEY] })]
    };
    const metaInfo = MetaInfo.of(
      [ItemLinkType.TYPE_KEY, TaskType.TYPE_KEY, FileType.TYPE_KEY, PersonType.TYPE_KEY],
      resultsSchema,
      [ItemLinkType.TYPE_KEY],
      'results'
    );
    metaInfo.partialUpdates = { [TaskType.TYPE_KEY]: taskUpdateProperties };
    const body = { type: itemLinkRelationType, sorting, paging };

    return this.httpClient
      .post<
        CommunicationServerResponse<ItemLink>
      >(`${CelumPropertiesProvider.properties.httpBaseAddress}/tasks/${sourceId}/links/search`, body)
      .pipe(
        map(res => {
          const entitiesResult = this.resultConsumerService.translateAndAddToStore(res, metaInfo);
          return {
            itemLinks: entitiesResult.entities[ItemLinkType.TYPE_KEY] as ItemLink[],
            paginationResult: entitiesResult.paginationResult
          };
        })
      );
  }

  public createItemLink(
    sourceTaskId: number,
    taskLinkRelationType: ItemLinkRelationType,
    targetTaskId: number
  ): Observable<ItemLink> {
    const body = { type: taskLinkRelationType, targetId: targetTaskId.toString(), targetContext: TargetContext.TASK };
    const metaInfo = MetaInfo.of(
      [ItemLinkType.TYPE_KEY, TaskType.TYPE_KEY, FileType.TYPE_KEY, PersonType.TYPE_KEY],
      ItemLinkType.instance().getSchema({ relationsFor: [TaskType.TYPE_KEY] })
    );
    metaInfo.partialUpdates = { [TaskType.TYPE_KEY]: taskUpdateProperties };

    return this.httpClient
      .post<ItemLink>(`${CelumPropertiesProvider.properties.httpBaseAddress}/tasks/${sourceTaskId}/links`, body)
      .pipe(
        map(res => {
          const entitiesResult = this.resultConsumerService.translateAndAddToStore(res, metaInfo);
          const itemLinks = entitiesResult.entities[ItemLinkType.TYPE_KEY];
          return DataUtil.isEmpty(itemLinks) ? null : (itemLinks[0] as ItemLink);
        })
      );
  }

  public deleteItemLink(taskLink: ItemLink): Observable<void> {
    const httpOptions = {
      body: { type: taskLink.type, targetId: taskLink.targetId },
      ...STRONGLY_CONSISTENT_OPTION
    };

    return this.httpClient.delete<void>(
      `${CelumPropertiesProvider.properties.httpBaseAddress}/tasks/${taskLink.sourceId}/links`,
      httpOptions
    );
  }

  public createTempTaskLink(
    sourceTaskId: number,
    taskLinkRelationType: ItemLinkRelationType,
    targetTask: Task
  ): ItemLink {
    return {
      id: '-1',
      type: taskLinkRelationType,
      targetId: targetTask.id,
      targetContext: TargetContext.TASK,
      targetObject: () => EMPTY,
      sourceId: sourceTaskId,
      entityType: ItemLinkType.instance()
    };
  }
}
